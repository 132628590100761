<template>
  <v-btn-toggle
    mandatory
    :value="value"
    dense
    dark
    @change="$emit('change', $event)"
  >
    <v-btn
      small
      :value="false"
      color="red lighten-4"
      active-class="red darken-3 elevation-2"
    >
      <v-icon left>
        mdi-close
      </v-icon>
      {{ negative.text }}
    </v-btn>
    <v-btn
      small
      :value="true"
      color="green lighten-4"
      active-class="green darken-3 elevation-2"
    >
      {{ positive.text }}
      <v-icon right>
        mdi-check-bold
      </v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'BtnToggle',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    positive: {
      type: Object,
      required: true
    },
    negative: {
      type: Object,
      required: true
    }
  }
}
</script>
