var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.create)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" Create question ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{attrs:{"error-messages":errors},model:{value:(_vm.question.title),callback:function ($$v) {_vm.$set(_vm.question, "title", $$v)},expression:"question.title"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.question.image)?_c('img',{staticStyle:{"width":"300px"},attrs:{"src":_vm.$displayImage(_vm.question.image)}}):_vm._e(),_c('v-file-input',{attrs:{"required":"","accept":"image/*","label":"Image","error-messages":errors},model:{value:(_vm.question.image),callback:function ($$v) {_vm.$set(_vm.question, "image", $$v)},expression:"question.image"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"quiz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.quizes,"required":"","item-value":"id","item-text":"title","label":"Quiz","error-messages":errors},model:{value:(_vm.question.quiz_id),callback:function ($$v) {_vm.$set(_vm.question, "quiz_id", $$v)},expression:"question.quiz_id"}})]}}],null,true)}),_c('BtnToggle',{attrs:{"positive":{text: 'Active'},"negative":{text: 'Inactive'}},model:{value:(_vm.question.status),callback:function ($$v) {_vm.$set(_vm.question, "status", $$v)},expression:"question.status"}})],1),_c('v-card-actions',{staticClass:"pb-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('action.create'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }