<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(create)">
      <v-card>
        <v-card-title>
          Create question
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <editor
              v-model="question.title"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="image"
            rules="required"
          >
            <img
              v-if="question.image"
              :src="$displayImage(question.image)"
              style="width: 300px"
            >
            <v-file-input
              v-model="question.image"
              required
              accept="image/*"
              label="Image"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="quiz"
            rules="required"
          >
            <v-autocomplete
              v-model="question.quiz_id"
              :items="quizes"
              required
              item-value="id"
              item-text="title"
              label="Quiz"
              :error-messages="errors"
            />
          </ValidationProvider>
          <BtnToggle
            v-model="question.status"
            :positive="{text: 'Active'}"
            :negative="{text: 'Inactive'}"
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import editor from '@/components/Editor'
import BtnToggle from '@/components/BtnToggle'
import authHeader from '@/utils'

export default {
  components: { BtnToggle, editor },
  data: () => ({
    loading: false,
    question: {
      quiz_id: null,
      status: true
    },
    quizes: []
  }),
  beforeMount () {
    this.getQuizes()
  },
  methods: {
    create () {
      const formData = new FormData()
      formData.append('image', this.question.image)
      formData.append('quiz_id', this.question.quiz_id)
      formData.append('title', this.question.title)
      formData.append('status', this.question.status ? 1 : 0)
      this.loading = true
      this.$http
        .post('/questions', formData, { headers: authHeader() })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.create_success') })
          this.$router.push({ name: 'dashboard.question', params: { quiz_id: this.question.quiz_id } })
        })
    },
    getQuizes () {
      this.$http
        .get('/quizes/?take=500', { headers: authHeader() })
        .catch(error => {
          this.displayError(error)
        })
        .then(({ data }) => {
          this.quizes = data.data
          if (this.$route.params.quiz_id) {
            this.question.quiz_id = parseInt(this.$route.params.quiz_id)
          }
        })
    }
  }
}
</script>
