var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('editor',_vm._g(_vm._b({staticClass:"editor",attrs:{"api-key":"l1lcgeqkl3vog7mmltm0s6vvgm0y3o8haz1cctyeedzwky59","init":{
    selector: '.editor',
    height: 500,
    schema: 'html5',
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic forecolor backcolor | \
     alignleft aligncenter alignright alignjustify | \
     bullist numlist outdent indent | removeformat | help'
  }}},'editor',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }