<template>
  <editor
    v-bind="$attrs"
    api-key="l1lcgeqkl3vog7mmltm0s6vvgm0y3o8haz1cctyeedzwky59"
    :init="{
      selector: '.editor',
      height: 500,
      schema: 'html5',
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic forecolor backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat | help'
    }"
    class="editor"
    v-on="$listeners"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'Editor',
  components: {
    editor: Editor
  }
}
</script>
